import React, { useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Columns, Container } from 'react-bulma-components';
import { openSignUp } from '../../actions/ui';
import { connect } from 'react-redux';
import HowItWorks from 'resources/images/how_it_works.svg';
import HowItWorks1 from 'resources/images/how_it_works1.png';
import HowItWorks2 from 'resources/images/how_it_works2.png';
import HowItWorks3 from 'resources/images/how_it_works3.png';
import Line from 'resources/images/lines.png';
import MouseImg from 'resources/images/mouse.png';
import WhyToUseImg1 from 'resources/images/why_to_use1.svg';
import WhyToUseImg2 from 'resources/images/why_to_use2.svg';
import WhyToUseImg3 from 'resources/images/why_to_use3.svg';
import FirstSlide from 'resources/images/animation_first.svg';
import SecondSlide from 'resources/images/animation_second.svg';
import ThirdSlide from 'resources/images/animation_third.svg';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import Partner from './Partner';
import Slider from './Slider';
import IOSApp from './AppLinks/IOSApp'
import AndroidApp from './AppLinks/AndroidApp'
import './styles.scss';
import Testimonials from './Testimonials';



function Home(props) {

	const doctorSection = useRef(null);


	function scrollToDoctorBlock() {
		doctorSection.current.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	}

	return (
		<div className='content'>
			<section className='top-wrapper'>
				<section id='header'>


					<Container>
						<Navbar />
						<Columns style={{ alignItems: 'center' }}>
							<Columns.Column desktop={{ size: 5 }} tablet={{ size: 12 }}>
								<img alt='line' className='line' src={Line} />
								<span className='sub-title'>Mole checks | Online Dermatology</span>
								<h1 className='title'>Snap <span className='yellow'>and save</span> the skin you’re in.</h1>
								<h2 className='desc'>
									Scanoma <span className='highlight'>helps monitor your moles</span>, providing insight into potential
									<span className='highlight'> skin concerns</span>, and connects you with dermatologists
									for a <span className='highlight'>for expert opinions</span>. All from the privacy of your home.
								</h2>
								<div className='btnGroup app-link-group'>
									<IOSApp />
									<AndroidApp />
									{/* Removed inline comment for cleanliness */}
								</div>
								<br /><br />
								<img alt='mouse' className='mouse' src={MouseImg} />
							</Columns.Column>
							<Columns.Column desktop={{ size: 7 }} tablet={{ size: 12 }} className='slider-wrapper'>
								<Slider slider={[FirstSlide, SecondSlide, ThirdSlide]} />
							</Columns.Column>
						</Columns>
					</Container>
				</section>

				<section>
					<Testimonials />
				</section>


				<Container id='section1'>
					<h3 className='title'>
						<p>
							Why use <span className='blue font-italic'>Scanoma</span> ?
						</p>
					</h3>
					<div className='desc'>
					</div>
					<Columns>
						{/* <Columns.Column tablet={{ size: 6 }} desktop={{ size: 4 }} >
							<div className='card'>
								<img alt='reason' src={WhyToUseImg1} />
								<div className='wrapper'>
									<h4 className='title'>
										Fast, <span className='yellow font-italic'>free</span> and anonymous
									</h4>
									<div className='desc'>
										Easily perform self-checks with helpful guidance. Scanoma integrates computer vision technology with your phone’s camera to assist in capturing and reviewing moles you’re concerned about.
									</div>
								</div>
							</div>
						</Columns.Column> */}
						<Columns.Column tablet={{ size: 6 }} desktop={{ size: 4 }}>
							<div className='card'>
								<img alt='reason' src={WhyToUseImg2} />
								<div className='wrapper second'>
									<h4 className='title'>
										Get a diagnosis <span className='yellow font-italic'>online, anytime</span>
									</h4>
									<div className='desc'>
										Save yourself the hassle of booking and waiting for a skin check.
										Scanoma connects you with qualified dermatologists from the comfort
										of your own home to find out if you have a benign skin cancer or something else.
									</div>
								</div>
							</div>
						</Columns.Column>
						<Columns.Column desktop={{ size: 4 }} tablet={{ size: 6 }}>
							<div className='card'>
								<img alt='reason' src={WhyToUseImg3} />
								<div className='wrapper third'>
									<h4 className='title'>
										Tips and <span className='yellow font-italic'>advice to keep</span> your skin healthy
									</h4>
									<div className='desc'>
										Follow our Stay Healthy blog to learn how you can protect yourself from sun
										damage, know the signs and symptoms of skin cancer and practice
										everyday skin care.
									</div>
								</div>
							</div>
						</Columns.Column>
					</Columns>
				</Container>
			</section>

			<section className='bottom-wrapper'>



				<section id='section2'>
					<Container>
						<Columns>
							<Columns.Column className='image-wrapper'>
								<img src={HowItWorks} alt='how-it-works' />
							</Columns.Column>
							<Columns.Column>
								<h3 className='title'>
									How it <span className='blue font-italic'>works</span>
								</h3>
								<p className='desc'>
									Scanoma uses machine learning technology to analyze moles by comparing their appearance to thousands of images, helping identify potential concerns. This information assists users in deciding whether to seek further evaluation from a dermatologist.
								</p>
								<p className='steps'>
									<span className='count'>1</span>
									<p>Take a Picture of <span className='yellow font-italic'> Your Mole</span></p>
								</p>
								{/* <p className='steps'>
									<span className='count'>2</span>
									<p>Scanoma provides insight into how closely your mole resembles<span className='yellow font-italic'> concerning skin conditions </span></p>
								</p> */}
								<p className='steps'>
									<span className='count'>2</span>
									<p>Share results <span className='yellow font-italic'>with a dermatologist </span>for a professional evaluation.</p>
								</p>
							</Columns.Column>
						</Columns>
					</Container>
				</section>

				<Partner />



				<Container>
					<section className='subSection save-your-skin'>
						<h3 className='title'>
							<p>Save <span className='blue font-italic'>your skin</span> now with Scanoma</p>
							<img alt='line' src={Line} className='line' />
						</h3>
						<Columns>
							<div className='btnGroup app-link-group'>
								<IOSApp />
								<AndroidApp />
								{/* <div className="headerBtn yellow" onClick={scrollToDoctorBlock}>I’M A DOCTOR</div> */}
							</div>
						</Columns>
					</section>
				</Container>

				<Container id='section3'>
					<Columns>
						<Columns.Column size={5} className='signup'>
							<p className='title' ref={doctorSection}>
								<span className='blue font-italic'>Are you a doctor?</span>
								<p>Sign up for free</p>
							</p>
							<p className='desc'>
								Make extra income and help us save lives from skin cancer. Join other registered GPs and dermatologists on Scanoma.
							</p>
							<div className='signupBtn' onClick={props.openSignUp}>
								<Link to=''>Sign up instantly</Link>
							</div>
						</Columns.Column>
						<Columns.Column size={7} className='signup-description'>
							<div className='description-block'>
								<img alt='Additional Money' src={HowItWorks1} />
								<div className='description'>
									<h2 className='title'>
										<span className='yellow font-italic'>Find</span> more patients
									</h2>
									<p>
										Generate additional income through our online portal where you can browse and claim patient requests for a professional diagnosis.
									</p>
								</div>
							</div>
							<div className='description-block'>
								<img alt='Additional Money' src={HowItWorks2} />
								<div className='description'>
									<h2 className='title'>
										<span className='yellow'>Connect</span> faster
									</h2>
									<p>
										Respond to patients quicker through the easy to use app, where they’ll receive notifications and an email alerting them of your analysis.
									</p>
								</div>
							</div>
							<div className='description-block'>
								<img alt='Additional Money' src={HowItWorks3} />
								<div className='description'>
									<h2 className='title'>
										Provide <span className='yellow'>better care</span> at a lower cost
									</h2>
									<p>
										Spend more time caring for patients and less on finding and booking them in.
									</p>
								</div>
							</div>
						</Columns.Column>
					</Columns>
					<div className='signupBtn mobile' onClick={props.openSignUp}>
						<Link to=''>Sign Up</Link>
					</div>
					<hr />
				</Container>
			</section>
			<Footer />
		</div>
	);
}

export default withRouter(connect(null, { openSignUp })(Home));
